.slider-and-popup {
    overflow-x: hidden;

    .slider {
        .col-left {
            width: 45%;
            padding-right: 50px;
            text-align: right;

            .text {
                position: relative;

                .toggle-popup {
                    display: block;
                    color: var(--primary-color);
                    font-family: 'BankGothic';
                    font-weight: 700;
                    cursor: pointer;
                }
            }

            .next-slide {
                margin-top: 44px;
                cursor: pointer;

                span {
                    display: flex;
                    align-items: center;
                    margin-right: 24px;
                    text-transform: uppercase;
                    font-size: 12px;
                    transition: .2s ease-in;
                }

                &:hover {
                    span {
                        margin-right: 12px;
                    }
                }
            }
        }

        .col-right {
            width: 60%;
            margin-right: -10%;
        }

        .slider {
            .slide {
                width: 100%;
                height: 450px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.modal-info {
    position: fixed;
    z-index: 99;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.6);
    cursor: url('data:image/svg+xml;utf8,<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm0 10.293l5.293-5.293.707.707-5.293 5.293 5.293 5.293-.707.707-5.293-5.293-5.293 5.293-.707-.707 5.293-5.293-5.293-5.293.707-.707 5.293 5.293z"/></svg>') 24 24,auto;

    .modal-inner {
        position: relative;
        background: #fff;
        padding: 0;
        overflow-y: scroll;      

        .close-modal {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }

        .col {
            width: 33.333%;

            p {
                font-size: 12px;
            }
        }

        .col-text {
            padding: 30px 25px;

            h2,h3,h4 {
                font-size: 16px;
                font-weight: 700;
            }
        }

        .col-image {

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            } 
        }
    }
}

@media screen and (max-width: 1179px) {
    .modal-info {
        max-height: 100%;
        overflow-y: auto;
        padding-top: 20px;

        .modal-inner {
            width: 100%;
            height: calc(100% + 50px);

            .col-text, .col-image {
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .modal-info {
        .modal-inner {
            flex-wrap: wrap;

            .close-modal {
                filter: invert(100%);
            }

            .col {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .col-image {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 740px) {
    .modal-info {
        .modal-inner {
            flex-wrap: wrap;
            align-items: flex-start;

            .close-modal {
                filter: invert(100%);
            }

            .col {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                height: auto;
            }

            .col-image {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .slider-and-popup {
        .slider {
            flex-wrap: wrap-reverse;

            .col-left {
                width: 100%;
                padding-right: 0;
                margin-top: 26px;
                text-align: left;
            }

            .col-right {
                width: 100%;
                margin-right: 0;
            }
        }
    }
}
