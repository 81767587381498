.grid-images {
    .col {
        width: calc(50% - 40px);
    }

    .col-left {
        img {
            height: 350px;
            width: auto;
            margin-top: 80px;
        }
    }

    .col-right {
        img {
            margin-bottom: 80px;
        }
    }
}

@media screen and (max-width: 860px) {
    .grid-images {
        .col {
            width: calc(50% - 20px);
        }
    
        .col-left {
            img {
                height: 350px;
                width: auto;
                margin-top: 40px;
            }
        }
    
        .col-right {
            img {
                margin-bottom: 40px;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .grid-images {
        
        .wrap {
            flex-wrap: wrap;
        }

        .col {
           width: 100%;
           margin-bottom: 20px;
        }
    
        .col-left {
            width: 100%;
            img {
                height: auto;
                width: 100%;
            }
        }
    
        .col-right {
            width: 100%;
            img {
                margin-bottom: 20px;
            }
        }
    }
}