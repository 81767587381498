.media {
    .link {
        font-size: 28px;
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
            color: #000;
            border-bottom: unset;
        }
    }
}