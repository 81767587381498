.slider-surface-table {
    overflow-x: hidden;

    .slider {
        .col-image {
            width: 60%;
            height: 620px;
            margin-left: -10%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        .col-table {
            width: 45%;
            padding-left: 50px;

            .title {
                font-size: 20px;
                font-weight: 700;
                color: var(--primary-color);
                font-family: 'BankGothic';
                padding-bottom: 24px;
                border-bottom: 1px solid #7070707c;
            }

            .row {
                padding: 14px 0;
                border-bottom: 1px solid #7070707c;

                h6 {
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: 400;
                    letter-spacing: 1px;
                }

                span {
                    font-size: 13px;
                    font-weight: 300;
                }
            }

            .slider-nav {
                margin-top: 30px;

                span {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    font-size: 12px;
                    transition: .2s ease-in;
                    cursor: pointer;

                    img {
                        margin-left: 24px;
                        transition: .2s ease-in;
                    }

                    &:hover {
                        img {
                            margin-left: 12px;
                        }
                    }
                }

                #deactive {
                    opacity: .4;
                    cursor: not-allowed;
                }
            }
        }
    }
}

@media screen and (max-width: 1026px) {
    .slider-surface-table {
        .slider {
            .col-table {
                padding-left: 0;
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .slider-surface-table {
        .slider {
            .slide-fw {
                flex-wrap: wrap;
            }

            .col-image {
                width: 100%;
                height: 360px;
                margin-bottom: 26px;
                margin-left: unset;
            }

            .col-table {
                width: 100%;
                padding-left: 0;
            }
        }
    }
}