@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,400&display=swap');

@font-face {
    font-family: 'BankGothic';
    src: url('../fonts/BankGothic.eot');
    src: url('../fonts/BankGothic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BankGothic.woff2') format('woff2'),
        url('../fonts/BankGothic.woff') format('woff'),
        url('../fonts/BankGothic.ttf') format('truetype'),
        url('../fonts/BankGothic.svg#BankGothic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BankGothic';
    src: url('../fonts/BankGothicBold.eot');
    src: url('../fonts/BankGothicBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BankGothicBold.woff2') format('woff2'),
        url('../fonts/BankGothicBold.woff') format('woff'),
        url('../fonts/BankGothicBold.ttf') format('truetype'),
        url('../fonts/BankGothicBold.svg#BankGothicBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

html {
    scroll-behavior: smooth;
}

main {
    overflow-x: hidden;
}

* {
    font-family: 'Open Sans', sans-serif;
    color: var(--text_color);
}

.container--small {
    max-width: 720px;
}

.container--big {
    max-width: 1680px;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .container {
        width: 100%;
        max-width: 100%;
    }
}