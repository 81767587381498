.button {
    background: var(--primary-color);
    padding: .25rem 1.5rem;
    border: 2px solid var(--primary-color);
    color: #fff;
    display: inline-block;
    transition: background 200ms ease-in-out;
    font-size: 12px;
    text-transform: uppercase;

    &:hover {
        border: 2px solid var(--primary-color);
        background: transparent;
        color: var(--primary-color);
    }
}