section.hero {
    padding-top: 188px;

    .hero__wrap {
        position: relative;

        .scroll-down-trigger {
            position: absolute;
            bottom: 580px;
            right: -40px;
            transform: rotate(90deg);
            cursor: pointer;
            text-decoration: none !important;
            border-bottom: none;

            span {
                display: flex;
                align-items: center;
                margin-right: 24px;
                text-transform: uppercase;
                font-size: 12px;
                transition: .2s ease-in;
            }

            &:hover {
                text-decoration: none !important;
                border-bottom: none;

                span {
                    transform: translateX(12px);
                    text-decoration: none;
                }
            }
        }

        .hero__title {
            font-size: 80px;
            font-weight: bold;
            line-height: 70px;
            margin-bottom: 3rem;
            font-family: 'BankGothic';
            color: var(--primary-color);
        }

        .hero__image {
            height: 465px;
            width: 100%;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 860px) {
    section.hero {
        .hero__wrap {
            .hero__title {
                font-size: 65px;
                line-height: 55px;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    section.hero {
        .hero__wrap {
            .hero__title {
                font-size: 35px;
                line-height: 45px;
            }
        }
    }
}