.fullwidth-slider {
    width: 100%;

    .slide {
        width: 100%;

        .slider-nav {
            margin-bottom: 20px;

            span {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-size: 12px;
                transition: .2s ease-in;
                cursor: pointer;

                img {
                    margin-left: 24px;
                    transition: .2s ease-in;
                }

                &:hover {
                    img {
                        margin-left: 12px;
                    }
                }
            }

            #deactive {
                opacity: .4;
                cursor: not-allowed;
            }

            span.title {
                font-size: 16px;
                font-weight: 700;
            }
        }

        .hero {
            width: 100%;
            height: 700px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .fullwidth-slider .slide .hero {
        height: 450px;
    }

    .fullwidth-slider .slide .slider-nav span.title {
        font-size: 14px;
    }
}

@media screen and (max-width: 650px) {
    .fullwidth-slider {
    
        .slide {
    
            .slider-nav {
                flex-wrap: wrap;

                span.title {
                    width: 100%;
                    margin-bottom: 12px;
                }
            }
        }
    }
}