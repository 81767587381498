header.site-header {
    padding: 3rem 0;
    .site-header__wrap {

        .logo {
            width: 317px;
            height: auto;
            object-fit: contain;
        }

        .close {
            display: none;
            position: absolute;
            top: 10px;
        }

        nav {
            display: flex;
            align-items: center;

            ul {
                display: flex;
                align-items: center;
                list-style: none;
                gap: 1.5rem;

                li {
                    a {
                        text-transform: uppercase;
                        font-size: 12px;
                    }
                }
            }
        }

        .mobile-menu {
            display: none;
        }
    }
}

@media screen and (max-width: 1140px) {
    header.site-header {
        .site-header__wrap {

            .close {
                display: block;
                position: absolute;
                top: 12px;
                width: 10px;
                right: 12px;
                filter: invert(100%);
            }
    

            nav {
                position: fixed;
                max-width: 280px;
                height: 100%;
                width: 100%;
                left: 0;
                background: #fff;
                top: 0;
                bottom: 0;
                z-index: 99;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-top: 25px;
                transform: translateX(-150%);
                transition: .2s ease-in-out;
                box-shadow: 26px 4px 50px 22px rgba(0,0,0,0.16);
    
                ul {
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    padding: 0;
                    text-align: left;
                    padding-left: 25px;

                    li a {
                        font-weight: 700;
                        font-size: 16px;
                    }
                }

                .button {
                    margin-left: 0;
                    margin-bottom: 25px;
                }
            }

            nav.active {
                transform: translateX(0);
            }

            img.mobile-menu {
                display: block;
                width: 30px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    header.site-header .site-header__wrap .logo {
        max-width: 200px;
    }
}