.footer {
    padding: 50px 0;
    background: var(--primary-color);


    .col-left {
        img {
            margin-bottom: 24px;
            max-width: 300px;
        }

        .content {
            h3 {
                color: #fff;
                font-size: 24px;
                font-weight: 700;
                font-family: 'BankGothic';
                margin-top: 28px;
            }

            p,a,i {
                color: #fff;

                a {
                    text-decoration: underline;
                }

                i,em {
                    color: #fff;
                }
            }

            a {
                text-decoration: underline;
            }
        }
    }

    .col-center {
        padding-top: 26px;

        h3 {
            font-weight: 700;
            font-family: 'BankGothic';
            color: #fff;
            margin-bottom: 28px;
        }
        a {
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }

    .col-right {
        padding-top: 26px;

        h3 {
            font-weight: 700;
            font-family: 'BankGothic';
            color: #fff;
            margin-bottom: 28px;
        }

        .btn-white {
            background: #fff;
            padding: .25rem 1.5rem;
            border: 2px solid #fff;
            color: var(--primary-color);
            display: inline-block;
            transition: background 200ms ease-in-out;
            text-transform: uppercase;
            font-size: 20px;    
            font-weight: 700;
            font-family: 'BankGothic';
        
            &:hover {
                border: 2px solid #fff;
                background: transparent;
                color: #fff;
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .footer {
        padding: 50px 0 2rem 0;

        .container {
            flex-wrap: wrap;
        }

        .col-left {
            width: 100%;
            img {
                max-width: 200px;
            }
        }

        .col-center {
            width: 100%;
            padding-top: 25px;
        }

        .col-right {
            width: 100%;
            padding-top: 25px;
        }
    }
}