section.label-text {
    padding: 5rem 0;

    .label-text__wrap {
        .label-text__label {
            text-transform: uppercase;
            font-size: 18px;
            display: flex;
            align-items: center;
            font-family: 'BankGothic';
            color: var(--primary-color);

            &::after {
                content: '';
                height: 2px;
                width: 50px;
                background: #cf2e2e;
                margin-left: 24px;
            }
        }
        .label-text__text {
            font-weight: bold;
            font-size: 38px;
            line-height: 52px;
        }
    }
}

@media screen and (max-width: 860px) {
    section.label-text {
        padding: 5rem 0 2rem 0;

        .label-text__wrap {
            .label-text__label {
                font-size: 14px;
            }
            .label-text__text {
                font-size: 26px;
                line-height: 38px;
            }
        }
    }
}

@media screen and (max-width: 620px) {
    section.label-text {
        .label-text__wrap {
            .label-text__label {
                font-size: 12px;
            }
            .label-text__text {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
}